div.focused-section > * {
  padding-left: 8px;
}

div.focused-section h2 {
  background: var(--home-page-focused-section-background);
  color: var(--home-page-focused-section);
  box-sizing: border-box;
  font-weight: 400;
  font-size: 1.1rem;
  color: var(--home-page-focused-section);
  height: 30px;
  margin-bottom: 0;
  line-height: 30px;
}

div.focused-section a {
  color: var(--home-page-focused-section-container);
  /* font-size: 0.9rem; */
}

div.focused-section .item-details {
  font-size: 0.7rem;
  margin-bottom: 10px;
  margin-top: 5px;
}

div.focused-section .label {
  font-weight: 600;
  margin-right: 5px;
}

div.focused-section .item-heading {
  font-weight: 700;
  font-size: 0.94rem;
  margin-top: 1px;
}

div.focused-section .missing-articles {
  margin-top: 1px;
  font-size: 1rem;
}

div.focused-section > div {
  font-size: 0.9rem;
  font-weight: 500;
}

div.focused-section .view-all-link {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 0.9rem;
}

div.focused-section {
  position: relative;
  height: 100%;
}
