@use "../view-page.scss";
@use "../DocumentPage/generic-popover.scss";

.judgement-act-page.view-page {
  mark {
    background-color: transparent;
    padding: 0;
  }

  &.searching {
    mark {
      background-color: rgb(253 252 159);

      &.current {
        background: orange;
      }
    }
  }

  .act-text {
    .note-icon {
      display: none;
    }

    p:last-child {
      margin-bottom: 0;
    }

    p > button + .r6o-annotation.ai-noted::before {
      content: "" !important;
    }

    p > .r6o-annotation.noted {
      .note-icon {
        color: rgb(0 81 128);
        display: inline-block;
        position: absolute;
        right: -20px;
      }
    }

    p > .r6o-annotation.ai-noted {
      &:not(:empty)::before {
        color: gray;
        margin-right: 3px;
      }

      @mixin ai-pct-list {
        @for $i from 90 through 100 {
          &.ai-pct-#{$i}:not(:empty)::before {
            content: "(#{($i/100)})";
          }
        }
      }

      @include ai-pct-list;
    }
  }

  // .app-page-main-content > div:last-of-type > div:last-of-type {
  //   margin-top: 40px;
  // }

  .card.highlight-card {
    div,
    button.btn.btn-link.inline-link {
      font-size: 1rem;
      text-align: justify;
    }
  }
}

#note-tooltip {
  opacity: 0.95;

  .tooltip-arrow::before {
    border-top-color: darkgray;
  }

  .rte-headless .ql-container {
    background-color: transparent;
    border-bottom: 1px solid lightgray;
  }

  .ql-editor p,
  .ql-editor.ql-blank::before {
    text-indent: 40px;
  }

  .tooltip-inner {
    background-color: aliceblue;
    border: 1px solid darkgray;
    max-width: 45vw;
    padding: 0;
    width: 45vw;
  }

  @media (max-width: 600px) {
    .tooltip-inner {
      width: 100vw;
    }
  }

  .btn.btn-link {
    color: rgb(0 81 128);
    padding: 0 0.25rem;

    &:first-child {
      margin-left: auto;
    }
  }
}

#annotation-popover {
  max-width: 700px;
  min-width: 700px;
  width: 700px;

  .input-group {
    button,
    button:focus,
    button:active {
      background-color: var(--components-select-filter-control-background) !important;
      border-color: var(--components-select-filter-control-border) !important;
      box-shadow: none !important;
      outline: 0 !important;
    }
  }

  button {
    margin-left: 0;
    margin-right: 0;

    &:last-of-type {
      margin-left: auto;
    }
  }
}

#annotation-modal {
  font-size: 1.2rem;
  height: auto;

  .offcanvas-body {
    padding-bottom: 2rem;
    padding-top: 0;
  }
}

#annotation-tooltip {
  .tooltip-inner {
    max-width: 100vw;
  }

  div {
    gap: 5px;
  }

  span {
    font-size: 1rem;
    font-weight: 500;
    line-height: 33px;
  }

  button.btn.btn-link {
    color: white;
    padding: 3px 6px;

    &:hover {
      opacity: 0.8;
    }

    &:active,
    &:focus {
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
}

#map,
.quick-view-map {
  height: calc(100vh - 160px);
  position: fixed;
  top: 130px;
}

.mobile-view {
  #map,
  .quick-view-map {
    height: calc(80vh - 130px);
    position: fixed;
    top: calc(20vh + 100px);
  }
}

.quick-view-map {
  max-width: 14px;
}

.quick-view-page-map {
  display: flex;
  justify-content: center;
  min-width: 24px;
  width: var(--bs-gutter-x, 0.75rem);
}

// @media (max-width: 1200px) {
//   #quick-view-page-map {
//     display: none;
//   }
// }

.document-map {
  background-color: var(--search-page-landing-page-container-background);
  border: 1px solid var(--search-page-landing-page-container-border);
  border-right: none;
  width: 0;
}

.app-page-navigation .personal-markers-tab {
  .rct-title {
    width: 100%;
  }

  .personal-marker div,
  .personal-marker svg {
    color: gray;
  }

  .personal-marker:hover div,
  .personal-marker:hover svg {
    color: white;
  }
}
