.search-landing-page {
  .app-page-main-content {
    max-width: 950px;
  }

  .opinion-tabs.nav {
    flex-wrap: nowrap;
    margin-bottom: 10px;
    margin-left: 1rem;
    margin-right: 0;
    margin-top: 1rem;

    .nav-item {
      min-width: 0;

      &:last-of-type {
        flex-shrink: 0;
      }

      &:only-child {
        flex-shrink: 1;
      }

      .nav-link {
        border-radius: 2px;
        color: var(--summary-view-page-nav-link);
        cursor: pointer;
        padding: 0.25rem 1rem;
        padding-top: 0;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .nav-link.active {
        background-color: transparent;
        border-bottom: 3px solid var(--summary-view-page-nav-link);
        color: var(--summary-view-page-nav-link-active);
        cursor: default;
      }
    }
  }
}
