@use "../DocumentPage/generic-popover.scss";

#documents-popover,
#documents-menu-modal {
  .popover-body {
    padding: 0;
  }

  .list-group-item a {
    font-weight: 400 !important;
  }

  .accordion-button {
    .copy-button {
      display: flex;
      margin-left: auto;
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }

    &.collapsed {
      .copy-button {
        display: none;
      }
    }
  }
}

.documents-menu-toggle div.link-body::after {
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.3em solid;
  content: "";
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
}
