.logo {
  background: var(--app-logo);
  float: left;
  width: 128px;
}

.navbar-light .navbar-brand {
  color: transparent;
  flex-shrink: 0;
  left: 17px;
  margin-left: 0;
  margin-right: 0;
}

.nav-link-label {
  display: none;
}

.navbar-light .navbar-brand:hover {
  text-decoration: none;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: var(--app-site-layout-background);
}

.navbar {
  background: var(--app-navbar-background);
  border: solid 1px rgb(0 0 0 / 15%);
  border-style: solid none;
  border-top: 0;
  border-top: none;
  margin: 0;
  padding: 0;
}

.navbar .navbar-nav {
  align-items: center;
  font-size: 1rem;
}

.nav-tabs {
  font-size: 1rem;
}

.navbar .navbar-nav.main-nav {
  flex-grow: 1;
  justify-content: center;
  min-width: 0;
}

.navbar .nav-link {
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  margin-left: 5px;
  margin-right: 5px;
}

.navbar .dropdown > .nav-link {
  margin-left: 0;
  margin-right: 5px;
}

.navbar .nav-link {
  cursor: pointer;
  height: 100%;
}

.navbar .nav-link div.link-body {
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-size: 1.4rem;
  gap: 5px;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.navbar .nav-link:hover:not(.active) div.link-body,
.navbar .nav-link.hovered div.link-body {
  background-color: rgb(245 245 245);
}

.navbar .nav-link-active,
.navbar .active {
  border-bottom: 3px solid var(--app-navbar-link-active);
}

.navbar-light .navbar-nav a.nav-link,
.navbar .nav-link:focus {
  color: var(--app-navbar-light-link);
}

.help-section {
  display: flex;
  margin-right: 1rem;
  width: 62px;
}

.help-section button {
  background-color: var(--app-help-section-btn-background);
  border: 1px solid var(--app-help-section-btn-border);
  border-radius: 2px;
  color: var(--app-help-section-btn);
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  margin-right: 5px;
  padding: 0.3rem 0.7rem;
  transition: all 0.5s;
}

.help-section button:hover {
  background-color: var(--app-help-section-btn-hover-background);
  border: 1px solid var(--app-help-section-btn-hover-border);
}

.landing-button-section {
  display: flex;
  margin-right: 1rem;
}

.landing-button-section button.btn.btn-light.load-more {
  background-color: var(--app-help-section-btn-background);
  border: 1px solid var(--app-help-section-btn-border);
  border-radius: 2px;
  color: var(--app-help-section-btn);
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  margin-right: 5px;
  padding: 0.3rem 0.7rem;
  transition: all 0.5s;
}

.landing-button-section button.btn.btn-light.load-more:hover {
  background-color: var(--app-help-section-btn-hover-background);
  border: 1px solid var(--app-help-section-btn-hover-border);
  color: var(--app-help-section-btn);
}

.user-bar {
  background-color: var(--app-user-bar-background);
  height: 40px;
  margin-right: calc(100% - 100vw);
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  z-index: var(--z-index-top-nav-bar);
}

.user-bar-mobile {
  border-top: 1px solid var(--app-navbar-light-link);
  height: 60px;
}

.user-bar.scrolled {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 5%), inset 0 -1px 0 rgb(0 0 0 / 15%);
}

.user-bar.navbar .nav-link {
  align-items: center;
  display: flex;
  gap: 5px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.user-bar.navbar .nav-link.active,
.user-bar.navbar .nav-link:hover {
  color: var(--app-navbar-light-link);
  height: 100%;
}

.user-bar.navbar > .navbar-nav {
  height: 100%;
}

.user-bar .user-menu .dropdown {
  margin-left: 0;
  margin-right: 0;
}

.user-bar .user-menu .dropdown > a {
  align-items: center;
  display: flex;
  font-size: 1.3rem;
}

.user-bar .user-menu .dropdown > a:hover {
  text-decoration: none;
}

.avatar {
  border-radius: 2px;
  color: var(--app-avatar);
  display: flex;
  font-weight: bold;
  height: 35px;
  line-height: 32px;
  text-align: center;
  width: 35px;
}

.avatar-menu {
  width: 330px;
}

.avatar-menu .dropdown-divider {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.avatar-menu .p {
  margin-bottom: 0;
  padding-left: 1.5rem;
}

.avatar-menu ul {
  margin-bottom: 0;
}

.doc-tabs {
  width: 800px;
}

.doc-tabs.navbar-nav .nav-link {
  border: none;
  flex: 0 1 33%;
  margin: 0;
  padding: 0;
  padding-bottom: 0.4rem;
  text-align: center;
}

.doc-tabs.navbar-nav .nav-link.active {
  border-bottom: 4px solid var(--app-navbar-link-hover);
}

.doc-tabs.navbar-nav {
  margin-bottom: 0.5rem;
}

.dropdown-menu .tab-pane.active {
  border: none !important;
}

/* Dropdown item styles */
.navbar-nav .dropdown .dropdown-item {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar-nav .dropdown .dropdown-item:hover,
.dropdown-menu .dropdown-item:hover {
  text-decoration: none;
  background-color: rgb(245, 245, 245); /* Optional: Light grey on hover */
  color: var(--app-navbar-light-link, #333) !important; /* Custom color */
}

/* Override the default blue color on active/focus state for dropdown items */
.navbar-nav .dropdown .dropdown-item:active,
.navbar-nav .dropdown .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus {
  background-color: transparent !important; /* Remove background */
  color: var(--app-navbar-light-link, #333) !important; /* Custom color */
}

@media (max-width: 649px) {
  .navbar-brand {
    display: none;
  }

  .user-bar div.link-body {
    flex-direction: column;
    justify-content: center;
  }

  .user-bar .nav-link-label {
    display: inline;
    font-size: 0.7rem;
  }

  svg.shortLogo {
    display: block;
  }

  svg.fullLogo {
    display: none;
  }
}

@media (min-width: 650px) {
  .user-bar .nav-link-label {
    display: none;
  }

  .user-bar .link-body svg {
    font-size: 1.3rem;
    width: 2rem;
  }

  .user-bar .link-body svg:hover {
    font-size: 1.5rem;
  }

  .user-bar .user-menu .dropdown > a {
    font-size: 1rem;
  }

  .user-bar .nav-link div.link-body {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .user-bar .nav-link div.link-body {
    font-size: 1rem !important;
  }

  svg.shortLogo {
    display: block;
  }

  svg.fullLogo {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-light .navbar-brand {
    height: 48px;
    width: 128px;
  }

  .user-bar .user-menu {
    height: 48px;
    width: 128px;
  }

  .navbar .nav-link {
    margin: 0 1rem;
  }

  .navbar .dropdown {
    margin: 0 1rem;
  }

  svg.shortLogo {
    display: none;
  }

  svg.fullLogo {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .navbar .nav-link {
    margin: 0 2rem;
  }

  .navbar .dropdown {
    margin: 0 2rem;
  }

  .user-bar {
    padding-right: 2rem !important;
  }

  svg.shortLogo {
    display: none;
  }

  svg.fullLogo {
    display: inline-block;
  }
}

#user-menu-modal {
  font-size: 1.2rem;
  height: auto;
}

#user-menu-modal .offcanvas-body {
  padding-bottom: 2rem;
  padding-top: 0;
}

#user-menu-modal .offcanvas-body svg {
  color: var(--app-avatar);
  margin-right: 20px;
}