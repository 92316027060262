.card-text pre {
  font-family: var(--font-family);
  font-size: 0.8rem;
  text-align: justify;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  margin: 0; 
  padding: 0; 
  max-width: 100%;
}

.copy-button button {
  color: var(--view-page-btn-link);
  font-size: 1rem;
  font-weight: 500;
}
