.textarea-button-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .form-control {
    background: var(--search-page-results-background);
    border: none;
    box-sizing: border-box;
    min-height: 60px;
    overflow: hidden;
    resize: none;
    width: 100%;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .btn.clear-search {
    border-bottom: 3px solid transparent;
    color: var(--search-page-results-current-filters-btn-clear-search);
    font-size: 0.9rem;
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }
  }
}
