.search-results-nav {
  background-color: var(--search-page-results-search-pane-background);
  flex-wrap: nowrap;
  margin-left: 20px;
  margin-right: 20px;
  overflow-x: auto;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--scroll-bar-color);
    border-radius: 20px;
  }

  @media (max-width: 800px) {
    margin-left: 5px;
    margin-right: 5px;
  }

  a.nav-link {
    align-items: center;
    border: 0;
    border-bottom: 3px solid transparent;
    color: var(--search-page-results-search-pane);
    display: flex;
    font-weight: 600;
    letter-spacing: 0.1em;
    margin: 0;

    & > span,
    & > img {
      margin: auto;
    }

    img {
      opacity: 0.7;
      transform: scale(0.85);
    }

    &:hover,
    &.active {
      background-color: var(--search-page-results-search-pane-active-background);
      border-bottom: 3px solid var(--search-page-results-search-pane-border);
      font-weight: 600;

      img {
        opacity: 0.9;
      }
    }

    .search-category-label {
      text-align: center;
    }

    &.active .search-category-label {
      display: inline !important;
    }

    .search-category-icon {
      height: 30px;
      margin-left: 5px;
      margin-right: 5px;
      width: 30px;
    }

    @media (max-width: 992px) {
      .search-category-count {
        display: none;
      }
    }

    @media (max-width: 600px) {
      font-size: 0.8rem;
      justify-content: center;
      padding: 3px 6px;
      white-space: nowrap;

      .search-category-icon {
        height: 25px;
        width: 25px;
      }

      .search-category-label {
        display: none;
      }

      .search-category-count {
        display: initial;
      }
    }
  }
}

.loading::after {
  animation: dotty steps(1, end) 1s infinite;
  content: "";
  display: inline-block;
}

@keyframes dotty {
  0% {
    content: "";
  }

  25% {
    content: ".";
  }

  50% {
    content: "..";
  }

  75% {
    content: "...";
  }

  100% {
    content: "";
  }
}

button.sort-asc-desc.asc {
  /* stylelint-disable-next-line function-url-quotes */
  background: var(--search-page-results-table-btn-background) url(../../../assets/sort-asc.svg)
    no-repeat 50%;
  background-size: 18px 18px;
  cursor: pointer;
  height: 37px;
  margin: 0 0 0 0.5rem;
  width: 37px;
}

button.sort-asc-desc.desc {
  /* stylelint-disable-next-line function-url-quotes */
  background: var(--search-page-results-table-btn-background) url(../../../assets/sort-desc.svg)
    no-repeat 50%;
  background-size: 18px 18px;
  border: none;
  cursor: pointer;
  height: 37px;
  margin: 0 0 0 0.5rem;
  width: 37px;
}

.copy-button {
  align-items: center;
  display: flex;
  margin-left: auto;
  opacity: 0.7;

  .value-label {
    display: none;
  }

  &:hover {
    opacity: 1;

    .value-label {
      display: inline;
    }
  }
}
